@import '~antd/dist/antd.less';

a {
  color: @pktb-gray;
}

.ant-alert {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  margin: 1em;
}

.ant-alert-info {
  background-color: #e8dee2;
  border: 1px solid #77445b;
}

@pktb-gray: #272725;@pktb-dk-purple: #452b5d;@pktb-md-purple: #5c1c96;@pktb-lt-purple: #a98ec1;@pktb-gold: #ffdd74;