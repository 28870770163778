.menu-bar {
  padding: 0 20px;
  overflow: hidden !important;
  @media (max-width: 1280px) {
    padding: 2px 20px 2px 44px;
  }
  background-color: @pktb-gray;
  border-bottom: solid 0px #e8e8e8;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  .logo {
    box-sizing: border-box;
    min-width: 200px;
    float: left;
    padding: 2px 34px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1280px) {
      min-width: 150px;
      padding: 0;
    }
    .navbar-logo {
      height: 60px;
      display: inline-block;
      @media (max-width: 1280px) {
        height: 60px;
      }
    }
  }
  .menu-content {
    width: 50%;
    float: left;
    height: 96px;

    @media (max-width: 1280px) {
      width: calc(100% - 210px);
      float: right;
      .left-menu,
      .right-menu {
        display: none;
      }
    }

    .bars-menu {
      color: @pktb-gold;

      margin-right: 6px;
      margin-top: 40px;
      display: none;
      background: none;
      .svg-inline--fa {
        font-size: 2em;
      }

      @media (max-width: 1280px) {
        width: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-right: 6px;
        margin-top: 15px;
        .bars-menu-text {
          margin-bottom: 0;
        }
      }
    }
    .ant-menu {
      font-size: 1.2em;
      background: none;
      color: white;

      .ant-menu-item {
        color: white;
      }
    }
    .ant-menu-horizontal > .ant-menu-item-selected {
      color: @pktb-gray;
      border-bottom: 0px solid transparent;
    }

    .menu-title {
      font-size: 1.2em;
      margin-top: auto;
      margin-bottom: auto;
      letter-spacing: 1px;
    }
    .left-menu-wrapper {
      box-sizing: border-box;
      height: 96px;
      overflow: hidden;
    }
    .left-menu {
      overflow: hidden;
      float: left;
      width: auto;
      margin-left: calc(20%);
      height: 96px;
      .nav-header {
        height: 96px;
      }

      @media (max-width: 1600px) {
        margin-left: calc(10%);
      }
      @media (max-width: 1400px) {
        margin-left: calc(5%);
      }
      @media (max-width: 1330px) {
        margin-left: 0px;
      }

      .left-submenu-item {
        margin-left: 0px;
        padding: 24px 0;
      }

      .ant-menu-horizontal {
        border-bottom: none;
        line-height: 96px;
      }
      .menu-bar .menu-content .ant-menu-horizontal > .ant-menu-item:hover,
      .menu-bar .menu-content .ant-menu-horizontal > .ant-menu-submenu:hover {
        color: #00788c;
        border-bottom: 0px solid transparent;
      }
    }
    .right-menu-wrapper {
      overflow: hidden;
      box-sizing: border-box;
      height: 96px;
    }
    .right-menu {
      overflow: hidden;
      float: right;
      .ant-menu-horizontal {
        border-bottom: none;
        line-height: 96px;
        margin-right: 34px;

        @media (max-width: 1220px) {
          margin-right: 0px;
        }
        h3 {
          margin-bottom: 0;
        }
      }
      .request-demo {
        position: relative;
        // top: 3px;
        @media (max-width: 1175px) {
          margin-bottom: 90px;
        }
      }
    }

    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-submenu:hover {
      color: #00788c;
      border-bottom: 2px solid transparent;
    }
    .ant-btn-primary {
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
  }
}

.drawer-menu {
  overflow: scroll;
  color: @pktb-gray;
  .drawer-logo {
    width: 200px;

    display: inline-block;
  }

  .ant-drawer-content-wrapper {
    height: 100vh !important;
    background-color: @pktb-gray;
  }
  .ant-drawer-header {
    padding: 24px 34px 24px 24px;

    border-bottom: 1px solid transparent;
    .left-float {
      float: left;
    }
    .right-float {
      float: right;
      height: 32px;
      margin-right: 0px;
      margin-top: 0px;
      .fa-arrow-alt-to-left {
        font-size: 1.5em;
      }
    }
  }
  .ant-drawer-title {
    font-family: acumin-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.4em;
  }
  .ant-menu-item-group {
    .ant-menu-item-group-title {
      font-size: 1.25em;
      padding-left: 24px;
    }
    .ant-menu-item-group-list {
      font-size: 1.25em;
      padding-left: 0px;
    }
  }

  .anticon {
    color: @pktb-gray;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-body {
    .right-menu-wrapper {
      padding-top: 40px;
      .ant-menu-item {
        padding: 0px 34px 0px 24px;
        height: 96px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .menu-content {
    width: calc(100% - 120px);
    float: right;
    .left-menu,
    .right-menu {
      display: none;
    }
  }
  .menu-desktop-arrow {
    position: absolute;
    top: 8px;
    right: 36px;

    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .svg-inline--fa.fa-w-14 {
    &.fa-chevron-down {
      width: 0.8em;
    }
    &.fa-chevron-up {
      width: 0.8em;
    }
    &.fa-bars {
      width: 1em;
      position: relative;
      top: 8px;
    }
  }
  .logo a {
    margin-left: -20px;
  }
  .menu-content .ant-menu-item,
  .menu-content .ant-menu-submenu-title {
    padding: 1px 20px;
    height: 96px;
    box-sizing: border-box;
  }
  .logo {
    .navbar-logo {
      padding: 0;
    }
  }
}

// When the submenu is clickedm, this popup will show under a clicked topic
.ant-menu-submenu-popup {
  .ant-menu-item-group {
    padding: 24px 0px 24px 0px;
  }
  .ant-menu-item-group-title {
    font-family: acumin-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1em;
    padding: 0px 34px 0px 24px;
  }
  .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    margin-left: 24px;
  }
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #a98ec1;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  position: relative;
  top: 0px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 0px solid transparent;
}

.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: 0px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0 10px;
  margin-top: 0px;
  margin-bottom: 0;
  padding: 0 10px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 10px;
  margin-top: 0px;
  margin-bottom: 0;
  padding: 0 0px;
  padding-right: 0;
  padding-left: 0;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: @pktb-md-purple;
}

.ant-menu-item-selected a {
  color: @pktb-md-purple;
}
.ant-menu-item a:hover {
  color: @pktb-md-purple;
}

.ant-menu-item-selected {
  color: @pktb-md-purple;
}
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid @pktb-dk-purple;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}

@pktb-gray: #272725;@pktb-dk-purple: #452b5d;@pktb-md-purple: #5c1c96;@pktb-lt-purple: #a98ec1;@pktb-gold: #ffdd74;