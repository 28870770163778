.header-copy {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 1.4em;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.subheader-copy {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.body-copy {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0;
}

.primary-color {
  color: #4d3589;
}

.secondary-color {
  color: #ffcb08;
}

.gray-color {
  color: #252227;
}

.white-color {
  color: #ffffff;
}

.link-color {
  cursor: pointer;
  &:hover {
    color: #5c1c96;
    &.white-color {
      color: #a98ec1;
    }
  }
}

@pktb-gray: #272725;@pktb-dk-purple: #452b5d;@pktb-md-purple: #5c1c96;@pktb-lt-purple: #a98ec1;@pktb-gold: #ffdd74;