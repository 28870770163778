.landing-page-wrapper {
  background-color: @pktb-gray;

  .landing-page-header {
    display: block;
    width: 80vw;
    margin: 0 auto;
  }
  .hero-image {
    display: block;
    overflow: visible;
    z-index: 1;
    margin: 2vw 10vw;
    width: 80vw;
  }
}
.order-online {
  background-color: @pktb-gold;
  width: 33%;
  margin: 1em 0;
  border-radius: 6px;
  .order-online-content {
    width: 100%;
    height: 100%;
    padding: 1em 2em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    &:hover {
      color: #5c1c96 !important;
    }
  }

  .order-online-text {
    margin-left: 1em;
  }
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.left {
  text-align: left;
}
.extra-tall {
  font-size: 2.8em;
}
.tall {
  font-size: 2.4em;
}
.medium {
  font-size: 2.1em;
}
.normal {
  font-size: 1.8em;
}

.action-item {
  margin-top: 20px;
}
.row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 80vw;
  margin: 2vw auto;
  @media (max-width: 1024px) {
    width: 100vw;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
  }
  .col-2lg {
    width: 40vw;
    margin-right: 2vw;
    @media (max-width: 1024px) {
      width: 100vw;
      order: 2;
    }
  }
  .col-2sm {
    width: 36vw;
    margin-right: 2vw;
    @media (max-width: 1024px) {
      width: 100vw;
      order: 1;
    }
  }
}
.about {
  @media (max-width: 1024px) {
    text-align: center;
    padding: 2em;
  }
}
.hours {
  @media (max-width: 1024px) {
    text-align: center;
    padding: 2em;
  }
}
.location {
  padding-left: 2vw;
  @media (max-width: 1024px) {
    text-align: center;
  }
}
.sub-image {
  width: 100%;
  object-fit: cover;
  width: 40vw;
  height: 38vw;
  @media (max-width: 1024px) {
    width: 80vw;
    height: 78vw;
    margin-left: 10vw;
  }
}

.content {
  padding: 40px;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  @media (max-width: 620px) {
    padding: 40px 10px;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.85);
  }
  .content-item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 620px) {
    .content-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
    }
    .action-item {
      .mobile-tile {
        width: 120px;
        height: 120px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        background-color: @pktb-lt-purple;
        &:hover {
          background-color: @pktb-md-purple;
        }
      }
    }
    .extra-tall {
      font-size: 2em;
    }
    .tall {
      font-size: 1.8em;
    }
    .medium {
      font-size: 1.4em;
    }
    .normal {
      font-size: 1.6em;
    }
  }
}

.main-container {
  .hero {
    position: relative;
    .hero-overlay {
      text-align: center;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .hero-image {
        display: block;
        overflow: visible;
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 620px) {
          width: 100%;
        }
      }
    }
  }
}

@pktb-gray: #272725;@pktb-dk-purple: #452b5d;@pktb-md-purple: #5c1c96;@pktb-lt-purple: #a98ec1;@pktb-gold: #ffdd74;